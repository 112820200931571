
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;500;800&display=swap");

* {
  font-family: "Poppins", sans-serif;
}

/* @extend display-flex; */
display-flex,
.display-flex,
.display-flex-center,
.signup-content,
.signin-content,
.social-login,
.socials {
  display: flex;
  display: -webkit-flex;
}

/* @extend list-type-ulli; */
list-type-ulli,
.socials {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

a:focus,
a:active {
  text-decoration: none;
  outline: none;
  transition: all 300ms ease 0s;
  -moz-transition: all 300ms ease 0s;
  -webkit-transition: all 300ms ease 0s;
  -o-transition: all 300ms ease 0s;
  -ms-transition: all 300ms ease 0s;
}

input,
select,
textarea {
  outline: none;
  appearance: unset !important;
  -moz-appearance: unset !important;
  -webkit-appearance: unset !important;
  -o-appearance: unset !important;
  -ms-appearance: unset !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  appearance: none !important;
  -moz-appearance: none !important;
  -webkit-appearance: none !important;
  -o-appearance: none !important;
  -ms-appearance: none !important;
  margin: 0;
}

input:focus,
select:focus,
textarea:focus {
  outline: none;
  box-shadow: none !important;
  -moz-box-shadow: none !important;
  -webkit-box-shadow: none !important;
  -o-box-shadow: none !important;
  -ms-box-shadow: none !important;
}

input[type="checkbox"] {
  appearance: checkbox !important;
  -moz-appearance: checkbox !important;
  -webkit-appearance: checkbox !important;
  -o-appearance: checkbox !important;
  -ms-appearance: checkbox !important;
}

input[type="radio"] {
  appearance: radio !important;
  -moz-appearance: radio !important;
  -webkit-appearance: radio !important;
  -o-appearance: radio !important;
  -ms-appearance: radio !important;
}

img {
  max-width: 100%;
  height: auto;
  filter: drop-shadow(5px 0px 3px rgba(0, 0, 0, 0.05));
}

.signin-image img {
  filter: drop-shadow(0px 7px 4px rgba(0, 0, 0, 0.05));
}

figure {
  margin: 0;
}

p {
  margin-bottom: 0px;
  font-size: 15px;
  color: #777;
}

h2 {
  line-height: 1.66;
  margin: 0;
  padding: 0;
  font-weight: bold;
  color: #222;
  font-family: "Poppins", sans-serif;
  font-size: 36px;
}

.main {
  height: 100vh;
  /* background: #f8f8f8cf; */
  /* padding: 150px 0; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.clear {
  clear: both;
}

body {
  font-size: 14px;
  line-height: 1.8;
  color: #222;
  /* background: #333433da; */
  font-weight: 300;
  font-family: "Poppins", sans-serif;
}

.container {
  width: 700px;
  /* height: 600px; */
  /* background: #fff; */
  margin: 0 auto;
  box-shadow: 0px 15px 16.83px 0.17px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0px 15px 16.83px 0.17px rgba(0, 0, 0, 0.05);
  -webkit-box-shadow: 0px 15px 16.83px 0.17px rgba(0, 0, 0, 0.05);
  -o-box-shadow: 0px 15px 16.83px 0.17px rgba(0, 0, 0, 0.05);
  -ms-box-shadow: 0px 15px 16.83px 0.17px rgba(0, 0, 0, 0.05);
  border-radius: 20px;
  -moz-border-radius: 20px;
  -webkit-border-radius: 20px;
  -o-border-radius: 20px;
  -ms-border-radius: 20px;
}

.display-flex {
  justify-content: space-between;
  -moz-justify-content: space-between;
  -webkit-justify-content: space-between;
  -o-justify-content: space-between;
  -ms-justify-content: space-between;
  align-items: center;
  -moz-align-items: center;
  -webkit-align-items: center;
  -o-align-items: center;
  -ms-align-items: center;
}

.display-flex-center {
  justify-content: center;
  -moz-justify-content: center;
  -webkit-justify-content: center;
  -o-justify-content: center;
  -ms-justify-content: center;
  align-items: center;
  -moz-align-items: center;
  -webkit-align-items: center;
  -o-align-items: center;
  -ms-align-items: center;
}

.position-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}

.sign-in {
  height: 80vh;
  display: grid;
  place-items: center;
}

.signup {
  height: 85vh;
  display: grid;
  place-items: center;
}

.signup-content {
  padding:  10px 0;
}

.signup-form,
.signup-image,
.signin-form,
.signin-image {
  width: 50%;
  overflow: hidden;
}

.signup-image {
  margin: 0 55px;
}

.form-title {
  margin-bottom: 33px;
  font-weight: bold;
}

.signup-image {
  margin-top: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

figure {
  margin-bottom: 50px;
  text-align: center;
}

.form-submit {
  display: inline-block;
  background: #6dabe4;
  color: #fff;
  border-bottom: none;
  width: auto;
  padding: 15px 39px;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  -o-border-radius: 5px;
  -ms-border-radius: 5px;
  margin-top: 25px;
  cursor: pointer;
}

.form-submit:hover {
  background: #4292dc;
}

#signin {
  margin-top: 16px;
}

.signup-image-link {
  font-size: 14px;
  color: #222;
  display: block;
  text-align: center;
}

.term-service {
  font-size: 13px;
  color: #222;
}

.signup-form {
  margin-left: 75px;
  margin-right: 75px;
  padding-left: 34px;
}

.register-form {
  width: 100%;
}

.form-group {
  position: relative;
  margin-bottom: 25px;
  overflow: hidden;
}

.form-group:last-child {
  margin-bottom: 0px;
}

input {
  width: 100%;
  display: block;
  border: none;
  border-bottom: 1px solid #999;
  padding: 6px 30px;
  font-family: "Poppins", sans-serif;
  box-sizing: border-box;
}

input::-webkit-input-placeholder {
  color: #999;
}

input::-moz-placeholder {
  color: #999;
}

input:-ms-input-placeholder {
  color: #999;
}

input:-moz-placeholder {
  color: #999;
}

input:focus {
  border-bottom: 1px solid #222;
}

input:focus::-webkit-input-placeholder {
  color: #222;
}

input:focus::-moz-placeholder {
  color: #222;
}

input:focus:-ms-input-placeholder {
  color: #222;
}

input:focus:-moz-placeholder {
  color: #222;
}

input[type="checkbox"]:not(old) {
  width: 2em;
  margin: 0;
  padding: 0;
  font-size: 1em;
  display: none;
}

input[type="checkbox"]:not(old) + label {
  display: inline-block;
  line-height: 1.5em;
  margin-top: 6px;
}

input[type="checkbox"]:not(old) + label > span {
  display: inline-block;
  width: 13px;
  height: 13px;
  margin-right: 15px;
  margin-bottom: 3px;
  border: 1px solid #999;
  border-radius: 2px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  -o-border-radius: 2px;
  -ms-border-radius: 2px;
  background: white;
  background-image: -moz-linear-gradient(white, white);
  background-image: -ms-linear-gradient(white, white);
  background-image: -o-linear-gradient(white, white);
  background-image: -webkit-linear-gradient(white, white);
  background-image: linear-gradient(white, white);
  vertical-align: bottom;
}

input[type="checkbox"]:not(old):checked + label > span {
  background-image: -moz-linear-gradient(white, white);
  background-image: -ms-linear-gradient(white, white);
  background-image: -o-linear-gradient(white, white);
  background-image: -webkit-linear-gradient(white, white);
  background-image: linear-gradient(white, white);
}

input[type="checkbox"]:not(old):checked + label > span:before {
  content: "\f26b";
  display: block;
  color: #222;
  font-size: 11px;
  line-height: 1.2;
  text-align: center;
  font-family: "Material-Design-Iconic-Font";
  font-weight: bold;
}

.agree-term {
  display: inline-block;
  width: auto;
}

label {
  position: absolute;
  left: 0px;
  top: 50%;
  transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  color: #222;
}

.label-has-error {
  top: 22%;
}

/* label.error {
  position: relative;
  background: url("../images/unchecked.gif") no-repeat;
  background-position-y: 3px;
  padding-left: 20px;
  display: block;
  margin-top: 20px;
} */

label.valid {
  display: block;
  position: absolute;
  right: 0;
  left: auto;
  margin-top: -6px;
  width: 20px;
  height: 20px;
  background: transparent;
}

label.valid:after {
  font-family: "Material-Design-Iconic-Font";
  content: "\f269";
  width: 100%;
  height: 100%;
  position: absolute;
  /* right: 0; */
  font-size: 16px;
  color: green;
}

.label-agree-term {
  position: relative;
  top: 0%;
  transform: translateY(0);
  -moz-transform: translateY(0);
  -webkit-transform: translateY(0);
  -o-transform: translateY(0);
  -ms-transform: translateY(0);
}

.material-icons-name {
  font-size: 18px;
}

.signin-content {
  padding-top: 67px;
  padding-bottom: 87px;
}

.social-login {
  align-items: center;
  -moz-align-items: center;
  -webkit-align-items: center;
  -o-align-items: center;
  -ms-align-items: center;
  margin-top: 80px;
}

.social-label {
  display: inline-block;
  margin-right: 15px;
}

.socials li {
  padding: 5px;
}

.socials li:last-child {
  margin-right: 0px;
}

.socials li a {
  text-decoration: none;
}

.socials li a i {
  width: 30px;
  height: 30px;
  color: #fff;
  font-size: 14px;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  -o-border-radius: 5px;
  -ms-border-radius: 5px;
  transform: translateZ(0);
  -moz-transform: translateZ(0);
  -webkit-transform: translateZ(0);
  -o-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-property: transform;
  transition-property: transform;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}

.socials li:hover a i {
  -webkit-transform: scale(1.3) translateZ(0);
  transform: scale(1.3) translateZ(0);
}

.zmdi-facebook {
  background: #3b5998;
}

.zmdi-twitter {
  background: #1da0f2;
}

.zmdi-google {
  background: #e72734;
}

.signin-form {
  margin-right: 90px;
  margin-left: 80px;
}

.signin-image {
  margin-left: 110px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

/* for secret page  */

.emp-profile {
  padding: 3%;
  margin-top: 3%;
  margin-bottom: 3%;
  border-radius: 0.5rem;
  background: #fff;
}
.profile-img {
  text-align: center;
}
.profile-img img {
  width: 50%;
  height: 50%;
  border-radius: 5px;
}
.profile-img .file {
  position: relative;
  overflow: hidden;
  margin-top: -20%;
  width: 70%;
  border: none;
  border-radius: 0;
  font-size: 15px;
  background: #212529b8;
}
.profile-img .file input {
  position: absolute;
  opacity: 0;
  right: 0;
  top: 0;
}
.profile-head h5 {
  color: #333;
}
.profile-head h6 {
  color: #0062cc;
}
.profile-edit-btn {
  border: none;
  border-radius: 1.5rem;
  width: 90%;
  padding: 2%;
  /* font-weight: 600; */
  color: #6c757d;
  cursor: pointer;
}
.proile-rating {
  font-size: 12px;
  color: #818182;
  margin-top: 5%;
}
.proile-rating span {
  color: #495057;
  font-size: 15px;
  font-weight: 600;
}
.profile-head .nav-tabs {
  margin-bottom: 5%;
}
.profile-head .nav-tabs .nav-link {
  font-weight: 600;
  border: none;
}
.profile-head .nav-tabs .nav-link.active {
  border: none;
  border-bottom: 2px solid #0062cc;
}
.profile-work {
  padding: 14%;
  margin-top: -15%;
}
.profile-work p {
  font-size: 15px;
  color: #818182;
  font-weight: 600;
  margin-top: 10%;
}
.profile-work a {
  text-decoration: none;
  color: #495057;
  font-weight: 400;
  font-size: 14px;
}
.profile-work ul {
  list-style: none;
}

#about-labels{
  color: black;
  font-weight: 400; 
}
/* .profile-tab label {
  color: black;
  font-weight: 400; 
} */

.profile-tab p {
  font-weight: 400;
  color: #0062cc;
}

@media screen and (max-width: 1200px) {
  .container {
    width: calc(100% - 30px);
    max-width: 100%;
  }
}

@media screen and (min-width: 1024px) {
  .container {
    max-width: 1200px;
  }
}

@media screen and (max-width: 768px) {
  .signup-content,
  .signin-content {
    flex-direction: column;
    -moz-flex-direction: column;
    -webkit-flex-direction: column;
    -o-flex-direction: column;
    -ms-flex-direction: column;
    justify-content: center;
    -moz-justify-content: center;
    -webkit-justify-content: center;
    -o-justify-content: center;
    -ms-justify-content: center;
  }

  .signup-form {
    margin-left: 0px;
    margin-right: 0px;
    padding-left: 0px;
    /* box-sizing: border-box; */
    padding: 0 30px;
  }

  .signin-image {
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 50px;
    order: 2;
    -moz-order: 2;
    -webkit-order: 2;
    -o-order: 2;
    -ms-order: 2;
  }

  .signup-form,
  .signup-image,
  .signin-form,
  .signin-image {
    width: 50%;
  }

  .social-login {
    justify-content: center;
    -moz-justify-content: center;
    -webkit-justify-content: center;
    -o-justify-content: center;
    -ms-justify-content: center;
  }

  .form-button {
    text-align: center;
  }

  .signin-form {
    order: 1;
    -moz-order: 1;
    -webkit-order: 1;
    -o-order: 1;
    -ms-order: 1;
    margin-right: 0px;
    margin-left: 0px;
    padding: 0 30px;
  }

  .form-title {
    text-align: center;
  }
  .about-info {
    margin: 40px 0;
  }
}

@media screen and (max-width: 400px) {
  .social-login {
    flex-direction: column;
    -moz-flex-direction: column;
    -webkit-flex-direction: column;
    -o-flex-direction: column;
    -ms-flex-direction: column;
  }

  .social-label {
    margin-right: 0px;
    margin-bottom: 10px;
  }
}

/*# sourceMappingURL=style.css.map */

.navbar-light .navbar-nav .nav-link {
  padding: 0 20px;
  color: rgba(0, 0, 0, 0.5);
}

.home-page {
  /* width: 100vw; */
  height: 90vh;
  background-image: linear-gradient(90deg, #ecf0ff 50%, #fff 50%);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}

.home-page .home-div p {
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 800;
  color: #3e64ff;
  letter-spacing: 4px;
  margin-bottom: 20px;
}

.home-page .home-div h1 {
  font-size: 60px;
  font-weight: 800;
  text-transform: capitalize;
  margin-bottom: 30px;
}

.home-page .home-div h2 {
  font-weight: 500;
}

.navbar-brand img {
  max-width: 100%;
  height: 30px;
  border-radius: 5px;
  filter: drop-shadow(5px 0px 3px rgba(0, 0, 0, 0.05));
}

.contact_info {
  width: 100%;
  padding-top: 70px;
}

.contact_info_item {
  width: calc((100% - 60px) / 3);
  height: 100px;
  border: solid 1px #e8e8e8;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.1);
  padding-left: 32px;
  padding-right: 15px;
}

.contact_info_image {
  width: 35px;
  height: 35px;
  text-align: center;
}

.contact_info_image img {
  max-width: 100%;
}

.contact_info_content {
  padding-left: 17px;
}

.contact_info_title {
  font-weight: 500;
}

.contact_info_text {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.5);
}

.contact_form {
  padding-top: 85px;
}

/* .contact_form_container {
} */

.contact_form_title {
  font-size: 30px;
  font-weight: 500;
  margin-bottom: 37px;
}

.contact_form_inputs {
  margin-bottom: 30px;
}

.input_field {
  width: calc((100% - 60px) / 3);
  height: 50px;
  padding-left: 25px;
  border: solid 1px #e5e5e5;
  border-radius: 5px;
  outline: none;
  /* color: #0e8ce4; */
  -webkit-transition: all 200ms ease;
  -moz-transition: all 200ms ease;
  -ms-transition: all 200ms ease;
  -o-transition: all 200ms ease;
  transition: all 200ms ease;
}

.input_field:focus,
.text_field:focus {
  border-color: #b2b2b2;
}

.input_field:hover,
.text_field:hover {
  border-color: #b2b2b2;
}

.input_field::-webkit-input-placeholder,
.text_field::-webkit-input-placeholder {
  font-size: 16px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.3);
}

.input_field:-moz-placeholder,
.text_field:-moz-placeholder {
  font-size: 16px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.3);
}

.input_field::-moz-placeholder,
.text_field::-moz-placeholder {
  font-size: 16px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.3);
}

.input_field:-ms-input-placeholder,
.text_field:-ms-input-placeholder {
  font-size: 16px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.3);
}

.input_field::input-placeholder,
.text_field::input-placeholder {
  font-size: 16px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.3);
}

.text_field {
  width: 100%;
  height: 160px;
  padding-left: 25px;
  padding-top: 15px;
  border: solid 1px #e5e5e5;
  border-radius: 5px;
  color: #0e8ce4;
  outline: none;
  -webkit-transition: all 200ms ease;
  -moz-transition: all 200ms ease;
  -ms-transition: all 200ms ease;
  -o-transition: all 200ms ease;
  transition: all 200ms ease;
}

.contact_submit_button {
  padding-left: 35px;
  padding-right: 35px;
  color: #ffffff;
  font-size: 18px;
  border: none;
  outline: none;
  cursor: pointer;
  margin-top: 24px;
}

.panel {
  width: 100%;
  height: 50px;
  background: #fafafa;
  margin-top: 120px;
}

.button {
  display: inline-block;
  background: #97cbfa;
  border-radius: 5px;
  height: 48px;
  -webkit-transition: all 200ms ease;
  -moz-transition: all 200ms ease;
  -ms-transition: all 200ms ease;
  -o-transition: all 200ms ease;
  transition: all 200ms ease;
}

.button a {
  display: block;
  font-size: 18px;
  font-weight: 400;
  line-height: 48px;
  color: #ffffff;
  padding-left: 35px;
  padding-right: 35px;
}

.button:hover {
  opacity: 0.8;
}

@media only screen and (max-width: 991px) {
  .contact_info_item {
    width: 100%;
    margin-bottom: 30px;
  }

  .contact_info_item:last-child {
    margin-bottom: 0px;
  }
  label {
    position: absolute;
    left: 15px;
    top: 50%;
    transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    color: #222;
  }
}

@media only screen and (max-width: 767px) {
  .input_field {
    margin-bottom: 30px;
    width: 100%;
  }

  .input_field:last-child {
    margin-bottom: 0px;
  }

  .home-page .home-div h1 {
    font-size: 40px;
    font-weight: 800;
    text-transform: capitalize;
    margin-bottom: 30px;
  }

  .home-page .home-div h2 {
    font-weight: 500;
    font-size: 25px;
  }
}

@media only screen and (max-width: 575px) {
  .contact_submit_button {
    font-size: 13px;
    padding-left: 25px;
    padding-right: 25px;
  }
}

/* 404 error page not found style  */

#notfound {
  position: relative;
  height: 100vh;
}

#notfound .notfound {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.notfound {
  max-width: 920px;
  width: 100%;
  line-height: 1.4;
  text-align: center;
  padding-left: 15px;
  padding-right: 15px;
}

.notfound .notfound-404 {
  position: absolute;
  height: 100px;
  top: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  z-index: -1;
}

.notfound .notfound-404 h1 {
  font-family: "Maven Pro", sans-serif;
  color: #ececec;
  font-weight: 900;
  font-size: 276px;
  margin: 0px;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.notfound h2 {
  font-family: "Maven Pro", sans-serif;
  font-size: 46px;
  color: #000;
  font-weight: 900;
  text-transform: uppercase;
  margin: 0px;
}

.notfound p {
  font-family: "Maven Pro", sans-serif;
  font-size: 16px;
  color: #000;
  font-weight: 400;
  text-transform: uppercase;
  margin-top: 15px;
}

.notfound a {
  font-family: "Maven Pro", sans-serif;
  font-size: 14px;
  text-decoration: none;
  text-transform: uppercase;
  background: #189cf0;
  display: inline-block;
  padding: 16px 38px;
  border: 2px solid transparent;
  border-radius: 40px;
  color: #fff;
  font-weight: 400;
  -webkit-transition: 0.2s all;
  transition: 0.2s all;
}

.notfound a:hover {
  background-color: #fff;
  border-color: #189cf0;
  color: #189cf0;
}

@media only screen and (max-width: 480px) {
  .notfound .notfound-404 h1 {
    font-size: 162px;
  }
  .notfound h2 {
    font-size: 26px;
  }
}

